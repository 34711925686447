/* global $ */
export default class Utils {
  isEmpty (element) {
    return !$.trim(element.html())
  }

  isIE () {
    const ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ')
    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)
  }
}
export const utils = new Utils()
