import { webService } from '../util/WebServiceManager'
import BaseView from './BaseView'

export default class ControlPanelView extends BaseView {
  sortTable(n, numeric) {
    if (typeof numeric === 'undefined') numeric = false
    let table
    let rows
    let switching
    let i
    let x
    let y
    let shouldSwitch
    let dir
    let switchcount = 0

    table = document.getElementById('groups')
    switching = true
    // Set the sorting direction to ascending:
    dir = 'asc'
    /* Make a loop that will continue until
    no switching has been done: */
    while (switching) {
      // Start by saying: no switching is done:
      switching = false
      rows = table.rows
      /* Loop through all table rows (except the
      first, which contains table headers): */
      for (i = 1; i < rows.length - 1; i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
        x = rows[i].getElementsByTagName('TD')[n]
        y = rows[i + 1].getElementsByTagName('TD')[n]
        let xValue = x.innerHTML.toLowerCase().replace(/<[^>]+>/g, '')
        let yValue = y.innerHTML.toLowerCase().replace(/<[^>]+>/g, '')
        /* Check if the two rows should switch place,
        based on the direction, asc or desc: */
        if (numeric) {
          if (dir === 'asc') {
            if (Number(xValue) > Number(yValue)) {
              // If so, mark as a switch and break the loop:
              shouldSwitch = true
              break
            }
          } else if (dir === 'desc') {
            if (Number(xValue) < Number(yValue)) {
              // If so, mark as a switch and break the loop:
              shouldSwitch = true
              break
            }
          }
        } else {
          if (dir === 'asc') {
            if (xValue > yValue) {
              // If so, mark as a switch and break the loop:
              shouldSwitch = true
              break
            }
          } else if (dir === 'desc') {
            if (xValue < yValue) {
              // If so, mark as a switch and break the loop:
              shouldSwitch = true
              break
            }
          }
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark that a switch has been done: */
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i])
        switching = true
        // Each time a switch is done, increase this count by 1:
        switchcount++
      } else {
        /* If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again. */
        if (switchcount === 0 && dir === 'asc') {
          dir = 'desc'
          switching = true
        }
      }
    }
  }

  /**
   * Show groups where subscription has expired.
   * @param element Element to display in.
   */
  showExpiredSubscriptions(element) {
    this.loadElement(element, webService.getExpiredSubscriptions)
  }

  /**
   * Show groups where subscription is about to expire.
   * @param element Element to display in.
   */
  showExpiringSubscriptions(element) {
    this.loadElement(element, webService.getExpiringSubscriptions)
  }
}
