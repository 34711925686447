import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Panel } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, registerables } from 'chart.js'

const ChartWrapped = ({ regionId = '', groupId = '', year = '', webService = undefined, intl = undefined }) => {
  const [labels, setLabels] = useState([])
  const [data, setData] = useState([])
  const [totalNumberOfTiles, setTotalNumberOfTiles] = useState('')

  const getActivityChartData = (regionId, groupId, year) => {
    webService.getActivityChartData(regionId, groupId, year, {
      success: data => {
        const result = JSON.parse(data)
        if (typeof result.resultData !== 'undefined') {
          setTotalNumberOfTiles(result.resultData.totalNumberOfTiles)
          setData(result.resultData.data)
          setLabels(result.resultData.labels)
        }
      },
      error: () => {},
    })
  }

  useEffect(() => {
    getActivityChartData(regionId, groupId, year)
  }, [])

  ChartJS.register(...registerables)

  const chartColors = {
    orange: 'rgb(188, 96, 49)',
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: intl.formatMessage({ id: 'activity.numberoftilesplayed' }) + ' (' + totalNumberOfTiles + ')',
        backgroundColor: chartColors.orange,
        borderColor: chartColors.orange,
        borderWidth: 1,
        data: data,
      },
    ],
  }
  const options = {
    responsive: true,
    legend: {
      position: 'top',
    },
  }
  return (
    <Panel data-testId="chart">
      <Panel.Heading>
        <i className="fa fa-bar-chart-o" /> <FormattedMessage id="activity.monthoverview" />
      </Panel.Heading>
      <Panel.Body>
        <Bar options={options} data={chartData} />
      </Panel.Body>
    </Panel>
  )
}

export const Chart = injectIntl(ChartWrapped)
