/* global $ */
import { webService } from '../util/WebServiceManager'
import BaseView from './BaseView'
import { utils } from '../util/Utils'
import React from 'react'
import { ImageSearch } from '../components/editor/imagesearch/ImageSearch.jsx'

export default class TileEditorView extends BaseView {
  constructor(app) {
    super(app)
    this.app = app
    this.activeImageBox = undefined
  }

  initialize() {
    // Initialize the search input and reset button on new tile page
    const newTileSearchContainer = $('.new-tile-search-container')
    if (newTileSearchContainer.length) {
      const searchInput = $('.new-tile-search-text')
      const resetButton = $('.new-tile-search-reset')
      if (searchInput.length && resetButton.length) {
        // Attach keyup event listener to the search input
        $(searchInput).on('keyup', () => {
          this.filterAppSections($(searchInput).val())
        })

        // Attach click event listener to the reset button
        $(resetButton).on('click', () => {
          $(searchInput).val('')
          this.filterAppSections('')
          $(resetButton).hide()
        })

        // Show the reset button only when there is input
        $(searchInput).on('input', () => {
          if ($(searchInput).val()) {
            $(resetButton).show()
          } else {
            $(resetButton).hide()
          }
        })
      }
    }
  }

  disableButtons() {
    $('#deleteButton').attr('disabled', 'disabled')
    $('#saveButton').attr('disabled', 'disabled')
    $('#saveAndStartButton').attr('disabled', 'disabled')
  }

  enableButtons() {
    $('#saveButton').removeAttr('disabled')
    $('#saveAndStartButton').removeAttr('disabled')
    $('#deleteButton').removeAttr('disabled')
  }

  /**
   * Filter app sections.
   * @param {*} text Filter text.
   */
  filterAppSections(text) {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout)
    }
    this.filterTimeout = setTimeout(() => {
      $('.appSectionItem').each(function (index, element) {
        const section = $(this)
        const sectionText = section.find('.appSectionText').text()
        const sectionTeaser = section.find('.appSectionTeaser').text()
        if (
          sectionText.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
          sectionTeaser.toLowerCase().indexOf(text.toLowerCase()) > -1
        ) {
          section.show()
          section.closest('.appSection').show()
        } else {
          section.hide()
        }
      })

      // Hide empty sections
      $('.appSection').each(function () {
        if ($(this).find('.appSectionItem:visible').length === 0) {
          $(this).hide()
        } else {
          $(this).show()
        }
      })

      // Show/hide reset button
      $('#new-tile-search-reset').css('display', text !== '' ? 'inline' : 'none')
    }, 300)
  }

  getSearchSectionResults(element, page) {
    let searchString = element.closest('.row-plangame').find('.row-plangame-searchstring')
    if (typeof searchString !== 'undefined') {
      searchString.val('')
    }
    let sectionElement = element.parent().parent().find('.row-plangames')
    let section = element.val()
    webService.getSearchSectionResults(section, page, {
      success: data => {
        if (typeof page !== 'undefined' && page > 0) sectionElement.goTo()
        let obj = $.parseJSON(data)
        if (sectionElement.html() !== obj.resultData) sectionElement.html(obj.resultData)
        $('.popup-iframe').magnificPopup({
          type: 'iframe',
        })
      },
    })
  }

  getSearchResults(element, page, event) {
    let sectionElement = element.parent().parent().find('.row-plangames')
    let section = element.val()
    if (typeof event !== 'undefined') {
      if (typeof event.keyCode !== 'undefined' && event.keyCode !== 0 && typeof page === 'undefined') {
        let c = String.fromCharCode(event.keyCode)
        let isWordcharacter = c.match(/\w/)
        if (!isWordcharacter) return
      }
    }

    // Allow Asian characters to search after one character
    if (section.match(/[\u3400-\u9FBF]/)) {
      if (section.length < 1) {
        return
      }
    } else if (section.length < 3) {
      return
    }

    // Reset section search
    let searchSection = element.closest('.row-plangame').find('.row-plangame-searchsection')
    if (typeof searchSection !== 'undefined') {
      searchSection.val('')
    }

    sectionElement.html('<p align="center"><i class="fa fa-spinner fa-spin"></i></p>')

    webService.getSearchResults(section, page, {
      success: data => {
        if (typeof page !== 'undefined' && page > 0) sectionElement.goTo()
        let obj = $.parseJSON(data)
        if (sectionElement.html() !== obj.resultData) {
          sectionElement.html(obj.resultData)
        }
        $('.popup-iframe').magnificPopup({
          type: 'iframe',
        })
      },
    })
  }

  getUserList(element, groupId, tileId) {
    this.loadElement(element, webService.getUserList, [groupId, tileId])
  }

  showAppSection(button, type) {
    // $('.btn-apps').each(function (index, element) {
    //   $(this).addClass('btn-default')
    //   $(this).removeClass('btn-primary')
    // })
    // $(button).removeClass('btn-default')
    // $(button).addClass('btn-primary')
    $('.appSection').each(function (index, element) {
      if (type !== 'all') $(this).hide()
      else $(this).show()
    })
    if (type !== 'all') $('#' + type).show()

    // Load lazy images
    $('img.lazy').lazyload()
  }

  showImagePicker(element) {
    const parent = $(element).closest('.imageBox')

    // Hide other search boxes and revert to file picker
    if (this.activeImageBox !== undefined && this.activeImageBox.length > 0 && !parent.is(this.activeImageBox)) {
      $('.imageSearch').each((index, element) => {
        $(element).hide()
        $(element).closest('.imageBox').find('.imagePicker').hide()
      })
      if (this.activeImageBox.find('.imageSearch').length > 0) {
        this.app.unmountComponent(this.activeImageBox.find('.imageSearch')[0])
      }
    }
    if (parent.length > 0) {
      parent.find('.imagePicker').show()
      parent.find('.imageSearch').hide()
      parent.goTo()
      this.activeImageBox = parent
    }
    return false
  }

  showImageSearch(element) {
    const parent = $(element).closest('.imageBox')

    // Hide other search boxes and revert to file picker
    if (this.activeImageBox !== undefined && this.activeImageBox.length > 0 && !parent.is(this.activeImageBox)) {
      $('.imageSearch').each((index, element) => {
        $(element).hide()
        $(element).closest('.imageBox').find('.imagePicker').hide()
      })
      if (this.activeImageBox.find('.imageSearch').length > 0) {
        this.app.unmountComponent(this.activeImageBox.find('.imageSearch')[0])
      }
    }
    if (parent.length > 0) {
      parent.find('.imagePicker').hide()
      parent.find('.imageSearch').show()
      this.activeImageBox = parent
      this.app.renderComponent(
        parent.find('.imageSearch')[0],
        <ImageSearch
          webService={webService}
          onImageSelected={(url, thumbnailUrl) => {
            this.showImagePicker(element)
            this.uploadImageUrl(parent, url, thumbnailUrl)
          }}
        />
      )
      parent.goTo()
    }
    return false
  }

  showPreview(anchor) {
    const planGames = anchor.closest('div.row-plangames')
    const gameId = anchor.closest('td').find('.gameIndexRowId').attr('value')
    const gameName = anchor.closest('td').find('.gameIndexRowGameName').attr('value')
    const appId = anchor.closest('td').find('.gameIndexRowAppId').attr('value')
    const appName = anchor.closest('td').find('.gameIndexRowAppName').attr('value')
    const questionRow = anchor.closest('.questionRow')
    const preview = questionRow.find('.row-plangame-preview')

    // Set values
    const planGame = anchor.closest('.row-plangame')
    planGame.find('.row-plangame-searchstring').val('')
    planGame.find('.row-plangame-searchsection').val('0')
    planGame.find('.row-plangame-id').val(gameId)
    planGame.find('.row-plangame-name').val(gameName)
    planGame.find('.row-plangame-appId').val(appId)
    planGame.find('.row-plangame-appName').val(appName)

    // Update preview
    const planGamePreview = anchor.closest('.planGamePreview')
    preview.html(planGamePreview.html())
    preview.find('.planGameAddButton').remove()

    const planGameImgSrc = preview.find('.planGameImage').attr('src')
    const planGameName = preview.find('.planGameName').html()
    questionRow.find('.questionImage').attr('src', planGameImgSrc)
    questionRow.find('.questionPreviewText').each(function () {
      $(this).html(planGameName)
    })
    this.app.getTileEditorQuestionsView().toggleQuestionPanel(questionRow)
    questionRow.goTo()
    planGames.html('')
  }

  submitLogin(element, invalidData) {
    if (element === undefined) {
      return
    }
    const username = $(element).closest('.modal-body').find('#username').val()
    const password = $(element).closest('.modal-body').find('#password').val()
    const remember = $(element).closest('.modal-body').find('#remember').val()
    webService.checkLogin(username, password, remember, {
      success: data => {
        const obj = $.parseJSON(data)
        if (obj.resultData === 'true') {
          window.ignoreLeavePage = true
          this.validated = true
          $('#loginForm').modal('hide')
          $('body').removeClass('modal-open')
          $('.modal-backdrop').remove()
          $('#contentForm').submit()
        } else {
          alert(invalidData)
        }
      },
      error: () => {
        $('#saveButton').removeAttr('disabled')
        $('#deleteButton').removeAttr('disabled')
      },
    })
  }

  toggleDeleteButton(element, deleteFromStack) {
    if (deleteFromStack) {
      $('#deleteTile').prop('checked', false)
    }

    if (element.checked) {
      $('#editBarDelete').show()
      $('#editBarSave').hide()
      $('#deleteButton').show()
      $('#saveAndStartButton').hide()
      $('#saveButton').hide()
    } else {
      $('#editBarDelete').hide()
      $('#editBarSave').show()
      $('#saveButton').show()
      $('#saveAndStartButton').show()
      $('#deleteButton').hide()
    }
  }

  toggleTargetGroupCheckbox(element) {
    const name = $(element).attr('name')
    const value = $(element).attr('value')
    const checked = $(element).is(':checked')
    $('[name="' + name + '"]').each((index, element) => {
      if (value === $(element).attr('value')) {
        $(element).prop('checked', checked)
      }
    })
  }

  uploadImage(field) {
    if (utils.isIE()) return
    const spinnerElement = field.closest('.imageBox').find('label')
    this.showSpinner(spinnerElement, false)
    webService.uploadFile(field, {
      success: data => {
        let obj = $.parseJSON(data)
        let resultData = $.parseJSON(obj.resultData)
        if (typeof resultData.id !== 'undefined' && resultData.id !== '') {
          field.closest('.imageBox').find('.row-image-preview').attr('src', resultData.thumbImage)
          field.closest('.imageBox').find('.row-image-zoom').attr('href', resultData.zoomImage)
          field.parent().find('.imageUploadId').val(resultData.id).trigger('change')
          field.val('')
        }
        this.hideSpinner(spinnerElement)
      },
      error: () => {
        console.log('Unable to upload image file.')
        this.hideSpinner(spinnerElement)
      },
    })
  }

  uploadImageUrl(field, url, thumbnailUrl) {
    const spinnerElement = field.closest('.imageBox').find('label')
    this.showSpinner(spinnerElement, false)
    webService.uploadImageUrl(url, thumbnailUrl, {
      success: data => {
        let obj = $.parseJSON(data)
        let resultData = $.parseJSON(obj.resultData)
        if (typeof resultData.id !== 'undefined' && resultData.id !== '') {
          field.closest('.imageBox').find('.row-image-preview').attr('src', resultData.thumbImage)
          field.closest('.imageBox').find('.row-image-zoom').attr('href', resultData.zoomImage)
          field.parent().find('.imageUploadId').val(resultData.id).trigger('change')
          field.val('')
        }
        this.hideSpinner(spinnerElement)
      },
      error: () => {
        console.log('Unable to upload image file.')
        this.hideSpinner(spinnerElement)
      },
    })
  }

  uploadSound(field) {
    if (utils.isIE()) return
    console.log('Upload sound file')
    const spinnerElement = field.closest('.soundBox').find('label')
    this.showSpinner(spinnerElement, false)
    webService.uploadFile(field, {
      success: data => {
        let obj = $.parseJSON(data)
        let resultData = $.parseJSON(obj.resultData)
        if (typeof resultData.id !== 'undefined' && resultData.id !== '') {
          field.parent().find('.soundUploadId').val(resultData.id)
          field.closest('.soundPicker').find('.soundUrl').val(resultData.sound)
          field.closest('.soundPicker').find('.play-button').show()
          field.closest('.soundPicker').find('.pickSound').hide()
          field.val('')
        }
        this.hideSpinner(spinnerElement)
      },
      error: () => {
        console.log('Unable to upload sound file.')
        this.hideSpinner(spinnerElement)
      },
    })
  }

  uploadSoundData(field, soundData, soundType) {
    if (utils.isIE()) return
    console.log('Upload sound data: ' + field + '/' + soundType)
    const spinnerElement = field.closest('.soundBox').find('label')
    this.showSpinner(spinnerElement, false)
    webService.uploadSoundData(soundData, soundType, {
      success: data => {
        let obj = $.parseJSON(data)
        let resultData = $.parseJSON(obj.resultData)
        if (typeof resultData.id !== 'undefined' && resultData.id !== '') {
          field.parent().find('.soundUploadId').val(resultData.id)
          field.closest('.soundPicker').find('.soundUrl').val(resultData.sound)
          field.closest('.soundPicker').find('.play-button').show()
          field.closest('.soundPicker').find('.pickSound').hide()
          field.val('')
        }
        this.hideSpinner(spinnerElement)
      },
      error: () => {
        console.log('Unable to upload file.')
        this.hideSpinner(spinnerElement)
      },
    })
  }

  validateForm(form, confirmDelete, missingTitle) {
    if (this.validated) return true
    if (typeof form.deleteTile !== 'undefined') {
      if (form.deleteTile.checked) {
        const agree = this.confirm('ConfirmDelete')
        if (!agree) return false
        window.ignoreLeavePage = true
      }
    }
    if (typeof form.deleteTileFromStack !== 'undefined') {
      if (form.deleteTileFromStack.checked) {
        const agree = confirm('ConfirmDelete')
        if (!agree) return false
        window.ignoreLeavePage = true
      }
    }
    if (form.name.value === '') {
      alert(missingTitle)
    } else {
      this.disableButtons()
      webService.isLoggedIn({
        success: isLoggedIn => {
          if (isLoggedIn) {
            this.validated = true
            $('#contentForm').submit()
            return true
          } else {
            $('#loginForm').modal('show')
            $('#loginForm').on('hidden.bs.modal', e => {
              this.enableButtons()
            })
          }
        },
        error: () => {
          this.enableButtons()
        },
      })
    }
    return false
  }
}
