/* global $ */
/* global app */
import BaseView from './BaseView'

export default class SoundPickerView extends BaseView {
  constructor (app) {
    super()
    this.app = app
    this.showSoundRecorder = this.showSoundRecorder.bind(this)
    this.currentRecorder = undefined
    this.recorderReady = true
  }

  deleteSounds (element) {
    if (element.is(':checked')) {
      element.closest('.soundPicker').find('.row-sound').val('')
      element.closest('.soundPicker').find('.row-soundFile').val('')
    }
  }

  hideSoundRecorders () {
    $('.soundPicker').each(function () {
      $(this).find('.recordSound').hide()
    })
  }

  isReady () {
    return window.recorderReady
  }

  recorderReady () {
    window.recorderReady = true
  }

  resetRecorder () {
    window.reset = true
  }

  resetSoundVideo (element) {
    $(element).closest('.soundPicker').find('.row-soundVideo').val('')
  }

  resetSoundFile (element) {
    $(element).closest('.soundPicker').find('.row-sound').val('')
    $(element).closest('.soundPicker').find('.row-soundType').val('')
    $(element).closest('.soundPicker').find('.soundUploadId').val('')
    $(element).closest('.soundPicker').find('.soundUrl').val('')
  }

  resetSoundRecorder (element) {
    $(element).closest('.soundPicker').find('.row-sound').val('')
    $(element).closest('.soundPicker').find('.row-soundType').val('')
  }

  resized () {}

  setSoundFile (element) {
    $(element).closest('.well').find('.row-sound-delete').attr('checked', false)
    this.resetSoundRecorder(element)
    this.resetSoundVideo(element)
  }

  setSoundData (inputName, soundData, soundType) {
    const playButton = this.currentRecorder.closest('.soundPicker').find('.play-button')
    if (typeof playButton !== 'undefined') playButton.hide()
    this.resetSoundFile(this.currentRecorder)
    this.resetSoundVideo(this.currentRecorder)
    this.currentRecorder.closest('.soundPicker').find('.row-sound').val(soundData)
    this.currentRecorder.closest('.soundPicker').find('.row-soundType').val(soundType)
    app.getTileEditorView().uploadSoundData(this.currentRecorder, soundData, soundType)
  }

  setSoundVideo (element) {
    console.log('Set sound video')
    if ($(element).val() === '') return
    const playButton = $(element).closest('.soundPicker').find('.play-button')
    if (typeof playButton !== 'undefined') playButton.hide()
    $(element).closest('.well').find('.row-sound-delete').attr('checked', false)
    this.resetSoundFile(element)
    this.resetSoundRecorder(element)
  }

  showAppSoundRecorder (element) {
    this.currentRecorder = element.closest('.soundPicker').find('.recordSound')
  }

  showSoundFilePicker (element) {
    this.hideSoundRecorders()
    element.closest('.soundPicker').find('.videoSound').hide()
    element.closest('.soundPicker').find('.pickSound').toggle()
    element.closest('.soundPicker').find('.recordSound').hide()
    return false
  }

  showSoundVideo (element) {
    this.hideSoundRecorders()
    element.closest('.soundPicker').find('.videoSound').toggle()
    element.closest('.soundPicker').find('.pickSound').hide()
    element.closest('.soundPicker').find('.recordSound').hide()
    return false
  }

  showSoundRecorder (element) {
    // Hide file picker
    element.closest('.soundPicker').find('.pickSound').hide()
    element.closest('.soundPicker').find('.videoSound').hide()

    // Get containers
    const recordSound = element.closest('.soundPicker').find('.recordSound')
    this.currentRecorder = recordSound
    this.app.getAudioRecorder().startRecording(element)
    return false
  }

  uploadSoundData (inputName, soundData, soundType) {
    const playButton = this.currentRecorder.closest('.soundPicker').find('.play-button')
    if (typeof playButton !== 'undefined') playButton.hide()
    this.resetSoundFile(this.currentRecorder)
    this.resetSoundVideo(this.currentRecorder)
    this.currentRecorder.closest('.soundPicker').find('.row-sound').val('')
    this.currentRecorder.closest('.soundPicker').find('.row-soundType').val('')
    app.getTileEditorView().uploadSoundData(this.currentRecorder, soundData, soundType)
  }
}
