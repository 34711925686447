/* global $ */
export default class WebServiceManager {
  constructor() {
    this.lastAjax = undefined
    this.lastPageHTML = ''

    this.checkLogin = this.checkLogin.bind(this)
    this.getExpiredSubscriptions = this.getExpiredSubscriptions.bind(this)
    this.getExpiringSubscriptions = this.getExpiringSubscriptions.bind(this)
    this.getMostPopularTiles = this.getMostPopularTiles.bind(this)
    this.getGroups = this.getGroups.bind(this)
    this.getGroupUserList = this.getGroupUserList.bind(this)
    this.getPage = this.getPage.bind(this)
    this.getResults = this.getResults.bind(this)
    this.getResultsAnswers = this.getResultsAnswers.bind(this)
    this.getSearchResults = this.getSearchResults.bind(this)
    this.getSearchSectionResults = this.getSearchSectionResults.bind(this)
    this.getUserList = this.getUserList.bind(this)
    this.isLoggedIn = this.isLoggedIn.bind(this)
  }

  abortLastRequest(path) {
    if (this.lastPath === path && typeof this.lastAjax !== 'undefined') {
      this.lastAjax.abort()
    } else if (typeof this.lastAjax !== 'undefined') {
      this.lastAjax.abort()
    }
  }

  checkLogin(username, password, remember, options) {
    options.data = { username: username, password: password, remember: remember }
    this.loadFromServer('login/check', options)
  }

  getActivityChartData(regionId, groupId, year, options) {
    options.data = 'regionId=' + regionId + '&groupId=' + groupId + '&year=' + year
    this.loadFromServer('activity/chartdata', options)
  }

  getBaseUrl() {
    if (location.host.indexOf('localhost') !== -1) {
      return location.protocol + '//localhost/api/1.0/'
    }
    return location.protocol + '//' + location.host + '/api/1.0/'
  }

  getExpiredSubscriptions(options) {
    this.loadFromServer('activity/html/subscriptionsexpired', options)
  }

  getExpiringSubscriptions(options) {
    this.loadFromServer('activity/html/subscriptionsexpiring', options)
  }

  getGroups(searchString, options) {
    options.data = 'searchString=' + searchString
    this.loadFromServer('group/html/list/', options)
  }

  getGroupUserList(groupId, options) {
    options.data = 'groupId=' + groupId
    this.loadFromServer('user/list', options)
  }

  getMostPopularTiles(regionId, groupId, month, year, options) {
    options.data = 'regionId=' + regionId + '&groupId=' + groupId + '&month=' + month + '&year=' + year
    this.loadFromServer('activity/html/mostpopulartiles', options)
  }

  getPage(section, options) {
    const success = typeof options !== 'undefined' && options.hasOwnProperty('success') ? options.success : undefined
    const error = typeof options !== 'undefined' && options.hasOwnProperty('error') ? options.error : undefined
    let url = section + (section.indexOf('?') > 0 ? '&' : '?') + 'contentOnly=1'
    if (typeof this.lastAjax !== 'undefined') {
      this.lastAjax.abort()
    }
    this.lastAjax = $.ajax({
      type: 'GET',
      url: url,
      cache: false,
      success: function (html) {
        if (typeof success !== 'undefined') {
          success(html)
        }
      },
      error: function (xhr, ajaxOptions, thrownError) {
        if (typeof error !== 'undefined') {
          error(xhr, ajaxOptions, thrownError)
        }
      },
    })
  }

  getResults(tileId, options) {
    options.data = 'tileId=' + tileId
    this.loadFromServer('tile/html/resultList', options)
  }

  getResultsAnswers(id, options) {
    options.data = 'resultId=' + id
    this.loadFromServer('tile/html/results/', options)
  }

  getSearchResults(searchString, page, options) {
    this.abortLastRequest('tile/html/search/')
    options.data = 'searchString=' + searchString + (typeof page !== 'undefined' ? '&page=' + page : '')
    this.loadFromServer('tile/html/search/', options)
  }

  getSearchSectionResults(section, page, options) {
    this.abortLastRequest('tile/html/search/')
    options.data = 'section=' + section + (typeof page !== 'undefined' ? '&page=' + page : '')
    this.loadFromServer('tile/html/search/', options)
  }

  getUserList(groupId, tileId, options) {
    options.data = 'groupId=' + groupId + '&tileId=' + tileId
    this.loadFromServer('group/html/userlist', options)
  }

  /**
   * Search for images.
   * @param {string} Search string.
   * @param {{success: success, error: error}} options Options object.
   */
  imageSearch(searchString, options) {
    this.loadFromServer('image/search?query=' + searchString, options)
  }

  isLoggedIn(options) {
    options.returnType = 'bool'
    this.loadFromServer('login/check', options)
  }

  loadFromServer(path, options) {
    const data = typeof options !== 'undefined' && options.hasOwnProperty('data') ? options.data : ''
    const cache = typeof options !== 'undefined' && options.hasOwnProperty('cache') ? options.cache : false
    const post = typeof options !== 'undefined' && options.hasOwnProperty('post') ? options.post : false
    const success = typeof options !== 'undefined' && options.hasOwnProperty('success') ? options.success : undefined
    const error = typeof options !== 'undefined' && options.hasOwnProperty('error') ? options.error : undefined
    const returnType = typeof options !== 'undefined' && options.hasOwnProperty('returnType') ? options.returnType : false

    this.lastPath = path
    this.lastAjax = $.ajax({
      type: post ? 'POST' : 'GET',
      url: this.getBaseUrl() + path,
      data: data,
      cache: cache,
      success: data => {
        this.lastPath = ''
        if (typeof success !== 'undefined') {
          if (returnType === 'bool') {
            const obj = $.parseJSON(data)
            success(
              typeof obj.resultData !== 'undefined' &&
                (obj.resultData === 'true' ||
                  (typeof obj.resultData.success !== 'undefined' && obj.resultData.success.toString() === 'true'))
            )
          } else {
            success(data)
          }
        }
      },
      error: (xhr, ajaxOptions, thrownError) => {
        this.lastPath = ''
        if (typeof error !== 'undefined') {
          error(xhr, ajaxOptions, thrownError)
        }
      },
    })
  }

  resetNotifications(options) {
    this.loadFromServer('notification/reset', options)
  }

  saveUserPermission(userId, permission, options) {
    options.data = 'userId=' + userId + '&permission=' + permission
    this.loadFromServer('user/permission/save', options)
  }

  toggleLike(tileId, options) {
    options.data = 'tileId=' + tileId
    this.loadFromServer('tile/html/like', options)
  }

  uploadFile(file, options) {
    let fileData = file.prop('files')[0]
    this.uploadFileData(fileData, options)
  }

  uploadFileData(fileData, options) {
    const success = typeof options !== 'undefined' && options.hasOwnProperty('success') ? options.success : undefined
    const error = typeof options !== 'undefined' && options.hasOwnProperty('error') ? options.error : undefined
    let formData = new FormData()
    formData.append('file', fileData)

    $.ajax({
      url: this.getBaseUrl() + 'file/upload',
      dataType: 'text',
      cache: false,
      contentType: false,
      processData: false,
      data: formData,
      type: 'post',
      success: function (data) {
        if (typeof success !== 'undefined') {
          success(data)
        }
      },
      error: function (xhr, ajaxOptions, thrownError) {
        if (typeof error !== 'undefined') {
          error(xhr, ajaxOptions, thrownError)
        }
      },
    })
  }

  uploadImageUrl(url, thumbnailUrl, options) {
    const success = typeof options !== 'undefined' && options.hasOwnProperty('success') ? options.success : undefined
    const error = typeof options !== 'undefined' && options.hasOwnProperty('error') ? options.error : undefined

    let formData = new FormData()
    formData.append('url', url)
    formData.append('thumbnailUrl', thumbnailUrl)

    $.ajax({
      url: this.getBaseUrl() + 'image/uploadUrl',
      dataType: 'text',
      cache: false,
      contentType: false,
      processData: false,
      data: formData,
      type: 'post',
      success: function (data) {
        if (typeof success !== 'undefined') {
          success(data)
        }
      },
      error: function (xhr, ajaxOptions, thrownError) {
        if (typeof error !== 'undefined') {
          error(xhr, ajaxOptions, thrownError)
        }
      },
    })
  }

  uploadSoundData(soundData, soundDataType, options) {
    const success = typeof options !== 'undefined' && options.hasOwnProperty('success') ? options.success : undefined
    const error = typeof options !== 'undefined' && options.hasOwnProperty('error') ? options.error : undefined
    let formData = new FormData()
    formData.append('soundData', soundData)
    formData.append('soundDataType', soundDataType)

    $.ajax({
      url: this.getBaseUrl() + 'file/uploadSoundData',
      dataType: 'text',
      cache: false,
      contentType: false,
      processData: false,
      data: formData,
      type: 'post',
      success: function (data) {
        if (typeof success !== 'undefined') {
          success(data)
        }
      },
      error: function (xhr, ajaxOptions, thrownError) {
        if (typeof error !== 'undefined') {
          error(xhr, ajaxOptions, thrownError)
        }
      },
    })
  }

  /**
   * Validate if a group prefix is valid and available.
   * @param {WFWebServiceManagerOptions} options Options object.
   */
  validateGroupPrefix(prefix, options) {
    this.loadFromServer('group/verify/prefix?prefix=' + prefix, options)
  }
}
if (typeof window.webService === 'undefined') {
  window.webService = new WebServiceManager()
}
export const webService = window.webService
