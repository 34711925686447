export default class SoundManager {
  playSound (element, url, playButton, stopButton) {
    if (typeof playButton === 'undefined') {
      playButton = '<i class="fa fa-play"></i> ' + window.languageTexts.play
    }
    if (typeof stopButton === 'undefined') {
      stopButton = '<i class="fa fa-stop"></i> ' + window.languageTexts.stop
    }
    if (element.html() === stopButton) {
      if (typeof this.soundPlayer !== 'undefined') {
        this.soundPlayer.stop()
        this.soundPlayer = undefined
      }
    } else {
      if (typeof this.soundPlayer !== 'undefined') {
        this.soundPlayer.stop()
        this.soundPlayer = undefined
      }
      this.soundPlayer = window.soundManager.createSound({ url: url,
        autoLoad: true,
        autoPlay: true,
        onfinish: function () {
          element.html(playButton)
        },
        onsuspend: function () {
        },
        onstop: function () {
          element.html(playButton)
        },
        onload: function (success) {
          if (!success) {
            element.html(playButton)
          }
        }
      })
      element.html(stopButton)
    }
    return false
  }
}
