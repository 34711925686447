import RecordRTC from 'recordrtc/RecordRTC'
import { l } from '../util/LanguageManager'

export default class AudioRecorder {
  constructor() {
    this.element = undefined
    this.recorder = undefined
    this.microphone = undefined
    this.animateInterval = undefined
    this.countdownInterval = undefined
    this.progressTimer = undefined
    this.audio = document.querySelector('audio')
    this.isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob)
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    this.replaceAudio = this.replaceAudio.bind(this)
    this.reset = this.reset.bind(this)
    this.startRecordingCallback = this.startRecordingCallback.bind(this)
    this.stopRecording = this.stopRecording.bind(this)
    this.stopRecordingCallback = this.stopRecordingCallback.bind(this)
    this.startCountdown = this.startCountdown.bind(this)
  }

  captureMicrophone(callback) {
    if (typeof this.microphone !== 'undefined') {
      callback(this.microphone)
      return
    }
    if (!this.isRecordingSupported()) {
      alert(l('RecordingUnsupported'))
      return
    }
    navigator.mediaDevices
      .getUserMedia({
        audio: this.isEdge
          ? true
          : {
              echoCancellation: false,
            },
      })
      .then(function (mic) {
        console.log('Got mic: ' + mic)
        callback(mic)
      })
      .catch(function (error) {
        console.log('Unable to capture your microphone. Please check console logs.')
        console.error(error)
      })
  }

  isRecordingSupported() {
    if (typeof navigator.mediaDevices === 'undefined' || !navigator.mediaDevices.getUserMedia) {
      console.log('This browser does not supports WebRTC getUserMedia API.')
      if (navigator.getUserMedia) {
        console.log('This browser seems supporting deprecated getUserMedia API.')
      }
      return false
    }
    return true
  }

  replaceAudio(src) {
    const newAudio = Object.assign(document.createElement('audio'), {
      controls: true,
      autoplay: true,
      src: src || '',
    })

    if (this.audio) {
      const parentNode = this.audio.parentNode
      parentNode.innerHTML = ''
      parentNode.appendChild(newAudio)
    }
    this.audio = newAudio
  }

  click(el) {
    el.disabled = false // make sure that element is not disabled
    const evt = document.createEvent('Event')
    evt.initEvent('click', true, true)
    el.dispatchEvent(evt)
  }
  reset() {
    if (this.countdownInterval !== undefined) {
      clearInterval(this.countdownInterval)
    }
    if (this.element !== undefined) {
      this.element.find('.countdown').remove()
    }
    if (this.animateInterval !== undefined) {
      clearInterval(this.animateInterval)
      this.element.find('.fa').first().css({ color: '#ff0000' })
    }
  }
  startRecording(element) {
    if (typeof element === 'undefined') {
      return
    }

    // If recording is already started stop it
    if (this.recorder !== undefined) {
      this.stopRecording()
      return
    }
    this.reset()
    this.element = element
    this.inputName = element.closest('.well').find('.row-sound').attr('name')

    // Capture microphone
    this.captureMicrophone(this.startCountdown)
  }
  startCountdown(mic) {
    let countdownIndex = 3
    this.element.append('<span class="countdown"> (' + countdownIndex + ')</span>')
    this.countdownInterval = setInterval(() => {
      countdownIndex--
      if (countdownIndex === 0) {
        clearInterval(this.countdownInterval)
        this.element.find('.countdown').remove()

        // Animate record button
        let animateIndex = 0
        this.animateInterval = setInterval(() => {
          if (animateIndex === 0) {
            this.element.find('.fa').first().css({ color: '#666' })
            animateIndex++
          } else {
            this.element.find('.fa').first().css({ color: '#ff0000' })
            animateIndex = 0
          }
        }, 750)

        // Start recording
        this.startRecordingCallback(mic)
      } else if (this.element.find('.countdown').length > 0) {
        this.element.find('.countdown').html(' (' + countdownIndex + ')')
      }
    }, 1000)
  }
  startRecordingCallback(mic) {
    this.microphone = mic
    this.replaceAudio()
    this.audio.muted = true
    this.audio.srcObject = this.microphone
    const options = {
      type: 'audio',
      mimeType: 'audio/wav',
      numberOfAudioChannels: this.isEdge ? 1 : 2,
      checkForInactiveTracks: true,
      bufferSize: 16384,
    }
    options.recorderType = RecordRTC.StereoAudioRecorder
    if (navigator.platform && navigator.platform.toString().toLowerCase().indexOf('win') === -1) {
      //options.sampleRate = 48000 // or 44100 or remove this line for default
    } else {
      options.sampleRate = 48000
      options.bufferSize = 4096
      options.numberOfAudioChannels = 2
    }
    if (this.recorder) {
      this.recorder.destroy()
      this.recorder = undefined
    }
    this.recorder = RecordRTC(this.microphone, options)
    this.recorder.startRecording()

    // Set max length of recording to 30 seconds
    let units = 1
    const recordProgress = this.element.find('.recordProgress')
    recordProgress.css({ width: '0%', display: 'block' })
    this.progressTimer = setInterval(() => {
      if (units > 300) {
        if (this.recorder !== undefined) {
          clearInterval(this.progressTimer)
          this.stopRecording()
        }
      }
      recordProgress.css({ width: 100 / (300 / units) + '%' })
      units++
    }, 100)
  }
  stopRecording() {
    clearInterval(this.progressTimer)
    const recordProgress = this.element.find('.recordProgress')
    recordProgress.css({ width: '0%', display: 'none' })
    this.recorder.stopRecording(this.stopRecordingCallback)
  }
  stopRecordingCallback() {
    try {
      this.reset()
      const fileObject = new File([this.recorder.getBlob()], 'audio.wav', {
        type: 'audio/wav',
      })
      const reader = new FileReader()
      reader.readAsDataURL(fileObject)
      reader.onload = function () {
        console.log('Sound data loaded - starting upload')
        window.app.getSoundPickerView().uploadSoundData(this.inputName, reader.result, 'audio/wav')
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }

      if (this.recorder !== undefined) {
        this.recorder.destroy()
        this.recorder = undefined
      }
      if (this.microphone !== undefined) {
        this.microphone.stop()
        this.microphone = undefined
      }
    } catch (error) {
      console.log('Unable to stop recording')
      alert(l('RecordingFailed'))
    }
  }
}
