import {webService} from '../util/WebServiceManager'
import BaseView from './BaseView'

export default class RegisterView extends BaseView {
  getGroupResults (element, event) {
    let sectionElement = element.parent().parent().find('.register-searchGroupName-results')
    let searchString = element.val()
    if (typeof event !== 'undefined') {
      if (typeof event.keyCode !== 'undefined' && event.keyCode !== 0) {
        const c = String.fromCharCode(event.keyCode)
        const isWordCharacter = c.match(/\w/)
        if (!isWordCharacter) return
      }
    }
    if (searchString.length < 3) return
    this.loadElement(sectionElement, webService.getGroups, [searchString])
  }
}
