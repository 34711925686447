/* global $ */
import {utils} from '../util/Utils'
import {webService} from '../util/WebServiceManager'
import BaseView from './BaseView'

export default class TileResultsAnswersView extends BaseView {
  showAnswers (element, id) {
    let sectionElement = element.next().next('.resultRow')
    let sectionColumn = sectionElement.find('.resultColumn')
    if (sectionElement.is(':visible')) {
      sectionElement.hide()
      return
    } else {
      // Hide open result columns
      $('.resultRow').each(function () {
        $(this).hide()
      })
    }
    if (!utils.isEmpty(sectionColumn)) { //  && sectionColumn.html() != progressHtml
      element.jumpTo()
      sectionElement.show()
      return
    }
    element.jumpTo()
    sectionElement.show()
    sectionColumn.html(this.spinnerHTML)

    webService.getResultsAnswers(id, {
      success: (html) => {
        const obj = $.parseJSON(html)
        sectionColumn.html(obj.resultData)
      }
    })
  }
}
