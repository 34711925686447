require('@formatjs/intl-locale/polyfill')
window.jQuery = window.$ = require('../node_modules/jquery/dist/jquery.js')
require('../node_modules/jquery-ui/dist/jquery-ui.js') // Important to include before bootstrap-datepicker to avoid overriding
require('../node_modules/bootstrap/dist/js/bootstrap.js')
window.colorpicker = require('../node_modules/bootstrap-colorpicker/dist/js/bootstrap-colorpicker.js')
window.datepicker = require('../node_modules/bootstrap-datepicker/dist/js/bootstrap-datepicker.js')
require('../node_modules/magnific-popup/dist/jquery.magnific-popup.js')
require('../util/jail.js')
require('../util/jquery-i18n-properties/jquery.i18n.properties.js')
require('../util/jquery.history.js')
require('../util/jquery.dynotable.js')
require('../util/jquery.transit.min.js')
require('../util/select.js')
require('../util/initEditor.js')
require('../node_modules/recordrtc/RecordRTC')
require('./editor/App.jsx')

require('../node_modules/jquery-ui-touch-punch/jquery.ui.touch-punch.js')
require('../node_modules/bootstrap/dist/css/bootstrap.css')
require('../node_modules/bootstrap/dist/css/bootstrap-theme.css')
require('../node_modules/bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css')
require('../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.css')
require('../node_modules/magnific-popup/dist/magnific-popup.css')
require('./styles/font-awesome.min.css')
require('./editor/styles/variables.css')
require('./editor/styles/editor.css')
require('./editor/styles/editBar.css')
require('./editor/styles/layout.css')
require('./editor/styles/links.css')
require('./editor/styles/login.css')
require('./editor/styles/typography.css')
require('./editor/styles/bootstrap.css')
