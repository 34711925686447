import React, { useEffect, useState } from 'react'
import { FormGroup, FormControl, Panel, Row, Col } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'

const MostPopularTilesWrapped = ({
  year = '',
  regionId = '',
  groupId = '',
  webService = undefined,
  onLoad = () => {},
  intl = undefined,
}) => {
  const f = intl.formatMessage
  const [popularTiles, setPopularTiles] = useState('')
  const months = [
    f({ id: 'months.january' }),
    f({ id: 'months.february' }),
    f({ id: 'months.march' }),
    f({ id: 'months.april' }),
    f({ id: 'months.may' }),
    f({ id: 'months.june' }),
    f({ id: 'months.july' }),
    f({ id: 'months.august' }),
    f({ id: 'months.september' }),
    f({ id: 'months.october' }),
    f({ id: 'months.november' }),
    f({ id: 'months.december' }),
  ]
  const getMostPopularTiles = (regionId, groupId, month, year) => {
    webService.getMostPopularTiles(regionId, groupId, month, year, {
      success: data => {
        const result = JSON.parse(data)
        if (typeof result.resultData !== 'undefined') {
          setPopularTiles(result.resultData.toString())
          onLoad()
        }
      },
      error: () => {},
    })
  }

  const handleMonthChange = e => {
    setPopularTiles('')
    getMostPopularTiles(regionId, groupId, e.target.value, year)
  }

  // Get month to show initially
  const date = new Date()
  const currentYear = date.getFullYear()
  let currentMonth = 1
  if (year === currentYear) {
    currentMonth = date.getMonth() + 1
  }

  // Load most popular tiles
  useEffect(() => {
    getMostPopularTiles(regionId, groupId, currentMonth, year)
  }, [])

  return (
    <React.Fragment>
      <Panel data-testId="most-popular-tiles">
        <Panel.Heading>
          <i className="fa fa-file" /> <FormattedMessage id="activity.mostpopulartiles" />
        </Panel.Heading>
        <Panel.Body>
          <FormGroup controlId="formControlsSelect">
            <Row>
              <Col md={4} />
              <Col md={4}>
                <FormControl componentClass="select" placeholder="select" onChange={handleMonthChange}>
                  {months.map((month, index) => {
                    return (
                      <option key={index + 1} value={index + 1} selected={index + 1 === currentMonth}>
                        {month}
                      </option>
                    )
                  })}
                </FormControl>
              </Col>
              <Col md={4} />
            </Row>
          </FormGroup>
          {popularTiles === '' && (
            <div style={{ align: 'center' }}>
              <i className="fa fa-spinner fa-spin" />
            </div>
          )}
          {popularTiles !== '' && (
            <div
              dangerouslySetInnerHTML={{
                __html: popularTiles,
              }}
            />
          )}
        </Panel.Body>
      </Panel>
    </React.Fragment>
  )
}

export const MostPopularTiles = injectIntl(MostPopularTilesWrapped)
