/* global $ */
/* global jQuery */
/* global app */
window.ignoreLeavePage = false
window.reset = false
window.version = ''

// Bind to wrapper
if (typeof window.CefSharp !== 'undefined') {
  window.CefSharp.BindObjectAsync('wizeFloorExternal').then(function (result) {
    console.log('Bound to wrapper')
  })
}

function initializeGlobal() {
  // Initialize data picker
  var $datepicker = $('#datepicker-container input')
  if ($datepicker.data('datepicker')) {
    // If datepicker is already initialized, destroy it first
    $datepicker.datepicker('destroy')
  }
  // Then reinitialize the datepicker
  $datepicker.datepicker({
    format: 'mm/dd/yyyy',
    todayBtn: 'linked',
  })

  // Initialize color picker
  $('.colorpicker').colorpicker({
    format: 'hex',
  })

  // Close dropdown menu on click
  $('.dropdown-menu a').click(function (event) {
    const dropdown = $(event.currentTarget).closest('.dropdown').find("[data-toggle='dropdown']")
    dropdown.dropdown('toggle')
  })
  ;(function ($) {
    $.fn.goTo = function () {
      $('html, body').animate(
        {
          scrollTop: $(this).offset().top - 60 + 'px',
        },
        'fast'
      )
      return this // for chaining...
    }
  })(jQuery)
  ;(function ($) {
    $.fn.jumpTo = function () {
      $('html, body').scrollTop($(this).offset().top - 60)
      return this // for chaining...
    }
  })(jQuery)

  // Load lazy images
  $('img.lazy').lazyload()

  // Enable lazy loading of images
  $(function () {
    $('img.lazy').lazyload()
  })
}
window.initializeGlobal = initializeGlobal

function isReset() {
  if (window.reset) {
    window.reset = false
    return true
  }
  return false
}
window.isReset = isReset

// Kept for compatibility with iOS app
function setSoundData(inputName, soundData, soundType) {
  console.log(inputName + '/' + soundData + '/' + soundType)
  app.getSoundPickerView().setSoundData(inputName, soundData, soundType)
}
window.setSoundData = setSoundData

// Kept for compatibility with iOS app
function setVersion(versionNumber) {
  window.version = versionNumber
  $('#versionNumber1').html('Version ' + window.version)
  $('#versionNumber2').html('Version ' + window.version)
}
window.setVersion = setVersion
