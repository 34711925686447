/* global $ */
import { webService } from '../util/WebServiceManager'

export default class ControlPanelView {
  resetNotifications () {
    webService.resetNotifications({
      success: () => {
        $('.notificationBadge').each(function () {
          $(this).hide()
        })
      }
    })
  }
}
