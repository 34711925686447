import React, {useState, useRef} from 'react'
import {Row, Col, InputGroup, FormControl, Button, FormGroup} from 'react-bootstrap'
import {injectIntl} from 'react-intl'
import PropTypes from 'prop-types'

const ImageSearchWrapped = ({
  webService = undefined,
  intl = undefined,
  onImageSelected = undefined
}) => {
  const [searching, setSearching] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [results, setResults] = useState([])
  const searchTimeoutCallback = useRef(setTimeout(() => {
  }, 0))
  const f = intl.formatMessage

  const handleChange = (e) => {
    setSearchString(e.target.value)
  }

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      searchHandler()
    }
  }

  const searchHandler = () => {
    clearTimeout(searchTimeoutCallback.current)
    if (searchString.length > 0) {
      setResults([])
      setSearching(true)
      searchTimeoutCallback.current = setTimeout(() => {
        webService.imageSearch(searchString, {
          success: (data) => {
            const result = JSON.parse(data)
            if (typeof result.resultData !== 'undefined') {
              const resultData = JSON.parse(result.resultData)
              setResults(resultData)
            }
            setSearching(false)
          },
          error: () => {
            setSearching(false)
          }
        })
      }, 500)
    }
  }

  const imagePressed = (url, thumbnailUrl) => {
    onImageSelected(url, thumbnailUrl)
  }

  return <React.Fragment>
    <FormGroup className='imageSearchForm align-items-center'>
      <InputGroup>
        <FormControl 
          value={searchString} 
          placeholder={f({'id': 'imagesearch.placeholder'})} 
          onChange={handleChange} 
          onKeyDown={onKeyUp}
        />
        <InputGroup.Button>
          <Button onClick={searchHandler}>
            <i className='fa fa-search' />
          </Button>
        </InputGroup.Button>
      </InputGroup>
    </FormGroup>
    {searching && (
      <p align='center'>
        <i className='fa fa-spinner fa-spin' />
      </p>
    )}
    {results !== undefined && results.length > 0 && (
      <Row className='equal'>
        {results.map((image, index) => {
          return <React.Fragment key={index}>
            <Col md={3} sm={6} xs={6}>
              <img src={image.thumbnailUrl} width='100%' className='imageSearchResult'
                onClick={() => imagePressed(image.imageUrl, image.thumbnailUrl)} />
            </Col>
          </React.Fragment>
        })}
      </Row>
    )}
  </React.Fragment>
}

ImageSearchWrapped.propTypes = {
  webService: PropTypes.object,
  intl: PropTypes.object,
  onImageSelected: PropTypes.func
}

export const ImageSearch = injectIntl(ImageSearchWrapped)
