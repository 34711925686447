import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import { usePersistedState } from '../hooks/usePersistedState'

export const Users = ({ groupId = 0, webService = undefined, onLoad = () => {} }) => {
  const [filter, setFilter] = usePersistedState(`groupUsersFilter${groupId}`, 'activated')
  const [groupList, setGroupList] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [newGroupUrl, setNewGroupUrl] = useState('')
  const [newUrl, setNewUrl] = useState('')
  const [searchText, setSearchText] = usePersistedState(`groupUsersSearchText${groupId}`, '')
  const [selectedGroup, setSelectedGroup] = useState(undefined)
  const [strings, setStrings] = useState([])
  const [userList, setUserList] = useState(undefined)

  const getUserList = groupId => {
    if (webService === undefined) {
      return
    }
    webService.getGroupUserList(groupId, {
      success: data => {
        const result = JSON.parse(data)
        if (typeof result.resultData !== 'undefined') {
          setGroupList(result.resultData.groups)
          setUserList(result.resultData.users)
          setNewUrl(result.resultData.newUrl)
          setNewGroupUrl(result.resultData.newGroupUrl)
          setStrings(result.resultData.strings)
          setLoading(false)
          onLoad()
        }
      },
      error: () => {
        setLoading(false)
      },
    })
  }

  const getFilterName = () => {
    switch (filter) {
      case 'limited':
        return getString('UserTypeLimited')
      case 'common':
        return getString('UserTypeCommon')
      case 'admin':
        return getString('UserTypeAdmin')
      case 'activated':
        return getString('FilterActivated')
      case 'noaccess':
        return getString('FilterNotActivated')
    }
    return ''
  }

  const getPermissionIcon = type => {
    switch (type) {
      case 'noaccess':
        return 'ban'
      case 'limited':
        return 'user-times'
      case 'common':
        return 'user'
      case 'admin':
        return 'key'
    }
  }

  const getString = label => {
    if (strings.length === 0) {
      return label
    }
    return strings[label]
  }

  const isInFilter = user => {
    let inFilter = false
    if (selectedGroup !== undefined && selectedGroup.id !== 0) {
      if (Number(user.groupId) === Number(selectedGroup.id)) {
        inFilter = true
      } else {
        return false
      }
    }
    if (selectedGroup === undefined && user.groupId !== 0) {
      return false
    }
    switch (filter) {
      case 'admin':
        inFilter = user.userType === 'admin'
        break
      case 'common':
        inFilter = user.userType === 'common'
        break
      case 'limited':
        inFilter = user.userType === 'limited'
        break
      case 'activated':
        inFilter = user.activated
        break
      case 'noaccess':
        inFilter = !user.activated
        break
    }
    if (inFilter) {
      if (searchText !== '') {
        if (
          user.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
          user.username.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        ) {
          return true
        }
      } else {
        return true
      }
    }
    return false
  }

  const isGroupInFilter = group => {
    if (selectedGroup !== undefined) {
      return false
    }
    if (searchText !== '') {
      return group.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    }
    return true
  }

  const openGroup = group => {
    setSelectedGroup(group)
  }

  const saveUserPermission = (user, permission) => {
    if (webService === undefined) {
      return
    }
    webService.saveUserPermission(user.id, permission, {
      success: data => {
        const result = JSON.parse(data)
        if (result.resultData !== undefined) {
          const clonedUsers = Object.assign([], userList)
          if (result.resultData === true) {
            for (const clonedUser of clonedUsers) {
              if (clonedUser.id === user.id) {
                clonedUser.userType = permission
                clonedUser.activated = permission !== 'noaccess'
              }
            }
          }
          setUserList(clonedUsers)
        }
      },
      error: () => {},
    })
  }

  // Load most popular tiles
  useEffect(() => {
    getUserList(groupId)
  }, [groupId])

  const filteredGroupList = useMemo(() => {
    if (groupList === undefined) {
      return undefined
    }
    const groups = []
    for (const group of groupList) {
      if (isGroupInFilter(group)) {
        groups.push(group)
      }
    }
    return groups
  }, [groupList, filter, searchText, selectedGroup])

  const filteredUserList = useMemo(() => {
    if (userList === undefined) {
      return undefined
    }
    const users = []
    for (const user of userList) {
      if (isInFilter(user)) {
        users.push(user)
      }
    }
    return users
  }, [userList, filter, searchText, selectedGroup])

  return (
    <div>
      {loading && (
        <div style={{ textAlign: 'center' }}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )}
      {!loading && (
        <div>
          <Table style={{ marginBottom: 0 }}>
            <tbody>
              <tr>
                <td width="70%" colSpan={2}>
                  {selectedGroup === undefined ? (
                    <div>
                      {newUrl !== '' && newGroupUrl !== '' && (
                        <div className="btn-group">
                          <a className="btn btn-default dropdown-toggle groupUserButton" data-toggle="dropdown" href="#">
                            <i className="fa fa-plus"></i> <span className="caret"></span>
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a href={newUrl}>
                                <span className="fa fa-user"></span> {getString('NewUsers')}
                              </a>
                            </li>
                            <li>
                              <a href={newGroupUrl}>
                                <span className="fa fa-group"></span> {getString('NewGroup')}
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                      {filter !== 'activated' && (
                        <span className="groupUserFilterLabel">
                          {getString('Filter')}: {getFilterName()}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div>
                      <a className="btn btn-default" href="#" onClick={() => openGroup(undefined)}>
                        <i className="fa fa-arrow-left"></i>
                      </a>
                      <span className="groupUserFilterLabel">{selectedGroup.name}</span>
                    </div>
                  )}
                </td>
                <td width="30%">
                  <input
                    type="text"
                    value={searchText}
                    className="form-control hidden-xs"
                    placeholder={getString('Search')}
                    onChange={e => setSearchText(e.target.value)}
                  />
                </td>
                <td>
                  <div className="btn-group">
                    <a className="btn btn-default dropdown-toggle groupUserButton" data-toggle="dropdown" href="#">
                      <i className="fa fa-filter"></i> <span className="caret"></span>
                    </a>
                    <ul className="dropdown-menu pull-right">
                      <li>
                        <div className="groupUserFilterRow" onClick={() => setFilter('activated')}>
                          <span className={`fa fa-${filter === 'activated' ? 'check-' : ''}circle-o`}></span>{' '}
                          {getString('FilterActivated')}
                        </div>
                      </li>
                      <li>
                        <div className="groupUserFilterRow" onClick={() => setFilter('noaccess')}>
                          <span className={`fa fa-${filter === 'noaccess' ? 'check-' : ''}circle-o`}></span>{' '}
                          {getString('FilterNotActivated')}
                        </div>
                      </li>
                      <li className="divider"></li>
                      <li>
                        <div className="groupUserFilterRow" onClick={() => setFilter('limited')}>
                          <span className={`fa fa-${filter === 'limited' ? 'check-' : ''}circle-o`}></span>{' '}
                          {getString('UserTypeLimited')}
                        </div>
                      </li>
                      <li>
                        <div className="groupUserFilterRow" onClick={() => setFilter('common')}>
                          <span className={`fa fa-${filter === 'common' ? 'check-' : ''}circle-o`}></span>{' '}
                          {getString('UserTypeCommon')}
                        </div>
                      </li>
                      <li>
                        <div className="groupUserFilterRow" onClick={() => setFilter('admin')}>
                          <span className={`fa fa-${filter === 'admin' ? 'check-' : ''}circle-o`}></span>{' '}
                          {getString('UserTypeAdmin')}
                        </div>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>

          <Table striped>
            <tbody>
              {filteredGroupList.length === 0 && filteredUserList.length === 0 && (
                <tr>
                  <td colSpan={4} align="center">
                    <i>{getString('NoUsersFound')}</i>
                  </td>
                </tr>
              )}

              {selectedGroup === undefined &&
                filter === 'activated' &&
                filteredGroupList.length > 0 &&
                filteredGroupList.map(group => {
                  return (
                    <tr key={`group${group.id}`} className="groupUserGroupRow" onClick={() => openGroup(group)}>
                      <td width="40">
                        <i className="fa fa-folder groupUserGroupIcon"></i>
                      </td>
                      <td width="100%">
                        {group.name}
                        <div className="small1" style={{ color: '#666666' }}>
                          {group.numberOfUsers !== '' && (
                            <div>
                              <i className="fa fa-user"></i> {group.numberOfUsers}
                            </div>
                          )}
                        </div>
                      </td>
                      <td></td>
                      <td>
                        <a href={group.editUrl} className="btn btn-default groupUserButton">
                          <i className="fa fa-edit"></i>
                        </a>
                      </td>
                    </tr>
                  )
                })}
              {filteredUserList.length > 0 &&
                filteredUserList.map(user => {
                  return (
                    <tr key={`user${user.id}`} className="groupUserGroupRow">
                      <td width="40" onClick={() => (window.location.href = user.editUrl)}>
                        <a href={user.editUrl} className="asyncLoad">
                          {user.img !== '' ? (
                            <img src={user.img} width="40" className="img-rounded" />
                          ) : (
                            <i className="fa fa-user groupUserGroupIcon"></i>
                          )}
                        </a>
                      </td>
                      <td width="100%" onClick={() => (window.location.href = user.editUrl)}>
                        {user.name}
                        <div className="small1" style={{ color: '#666666' }}>
                          {user.username !== '' && <span>{user.username}&nbsp;</span>}
                          {user.email !== '' && (
                            <span>
                              <a href={`mailto:${user.email}`} className="grey1 hidden-xs">
                                <i className="fa fa-envelope"></i> {user.email}
                              </a>
                              &nbsp;
                            </span>
                          )}
                          {user.language !== '' && (
                            <span>
                              <i className="fa fa-globe"></i> {user.language}&nbsp;
                            </span>
                          )}
                          {user.numberOfExercises !== '' && (
                            <span>
                              <i className="fa fa-file-o"></i> {user.numberOfExercises}
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="btn-group pull-right">
                          <a className="btn btn-default dropdown-toggle groupUserButton" data-toggle="dropdown" href="#">
                            <i className={`fa fa-${getPermissionIcon(user.userType)}`}></i> <span className="caret"></span>
                          </a>
                          <ul className="dropdown-menu pull-right">
                            <li>
                              <div className="groupUserFilterRow" onClick={() => saveUserPermission(user, 'noaccess')}>
                                <span className={`fa fa-${user.userType === 'noaccess' ? 'check-' : ''}circle-o`}></span>{' '}
                                {getString('UserTypeNoAccess')}
                              </div>
                            </li>
                            <li>
                              <div className="groupUserFilterRow" onClick={() => saveUserPermission(user, 'limited')}>
                                <span className={`fa fa-${user.userType === 'limited' ? 'check-' : ''}circle-o`}></span>{' '}
                                {getString('UserTypeLimited')}
                              </div>
                            </li>
                            <li>
                              <div className="groupUserFilterRow" onClick={() => saveUserPermission(user, 'common')}>
                                <span className={`fa fa-${user.userType === 'common' ? 'check-' : ''}circle-o`}></span>{' '}
                                {getString('UserTypeCommon')}
                              </div>
                            </li>
                            <li>
                              <div className="groupUserFilterRow" onClick={() => saveUserPermission(user, 'admin')}>
                                <span className={`fa fa-${user.userType === 'admin' ? 'check-' : ''}circle-o`}></span>{' '}
                                {getString('UserTypeAdmin')}
                              </div>
                            </li>
                          </ul>
                        </div>

                        {/* <FormControl componentClass='select' className='form-control hidden-xs' value={user.userType} onChange={(e) => {saveUserPermission(user, e.target.value)}}>
                      <option value='noaccess'>{getString('UserTypeNoAccess')}</option>
                      <option value='limited'>{getString('UserTypeLimited')}</option>
                      <option value='common'>{getString('UserTypeCommon')}</option>
                      <option value='admin'>{getString('UserTypeAdmin')}</option>
                    </FormControl> */}
                      </td>
                      <td>
                        <a href={user.editUrl} className="btn btn-default groupUserButton">
                          <i className="fa fa-edit"></i>
                        </a>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  )
}
Users.propTypes = {
  groupId: PropTypes.number,
  webService: PropTypes.object,
  onLoad: PropTypes.func,
}
