/* global $ */
import { webService } from '../util/WebServiceManager'
import BaseView from './BaseView'

export default class GroupEditorView extends BaseView {
  disableButtons() {
    $('#deleteButton').attr('disabled', 'disabled')
    $('#saveButton').attr('disabled', 'disabled')
  }

  enableButtons() {
    $('#saveButton').removeAttr('disabled')
    $('#deleteButton').removeAttr('disabled')
  }

  validateForm(form, isRegion) {
    if (this.validated) return true

    // Check if deleting
    if (
      (typeof form.deleteRegion !== 'undefined' && form.deleteRegion.checked) ||
      (typeof form.deleteGroup !== 'undefined' && form.deleteGroup.checked)
    ) {
      const agree = this.confirm('ConfirmDelete')
      if (!agree) return false
      window.ignoreLeavePage = true
    }

    // Check input fields
    let errors = false
    if (form.groupName.value === '') {
      this.alert(isRegion ? 'MissingRegionName' : 'MissingGroupName')
      errors = true
    } else if (typeof form.groupPrefix !== 'undefined' && form.groupPrefix.value === '' && !isRegion) {
      this.alert('MissingGroupPrefix')
      errors = true
    }
    if (!errors) {
      this.disableButtons()
      webService.isLoggedIn({
        success: isLoggedIn => {
          if (isLoggedIn) {
            this.validated = true
            $('#contentForm').submit()
          } else {
            this.enableButtons()
          }
        },
        error: () => {
          this.enableButtons()
        },
      })
    }
    return false
  }

  validatePrefix(prefix) {
    webService.validateGroupPrefix($(prefix).val(), {
      success: data => {
        $('.invalidPrefix').remove()
        const obj = $.parseJSON(data)
        if (obj.resultData === 'false') {
          $(prefix).closest('.form-group').addClass('has-error')
          $(prefix).after('<div class="invalidPrefix"><i class="fa fa-warning" style="color:red"></i></div>')
        } else {
          $(prefix).closest('.form-group').removeClass('has-error')
          $('.invalidPrefix').remove()
        }
      },
    })
  }
}
