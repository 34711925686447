/* global $ */
import { l } from '../util/LanguageManager'

/**
 * Represents a base view for the editor.
 * @class
 */
export default class BaseView {
  /**
   * Creates an instance of BaseView.
   * @param {object} app - The application object.
   */
  constructor(app) {
    this.app = app
  }

  /**
   * Displays an alert with the specified text.
   * @param {string} text - The text to display in the alert.
   * @returns {void}
   */
  alert(text) {
    return alert(l(text))
  }

  /**
   * Displays a confirmation dialog with the specified text.
   * @param {string} text - The text to display in the confirmation dialog.
   * @returns {boolean} - A boolean indicating whether the user clicked OK or Cancel.
   */
  confirm(text) {
    return confirm(l(text))
  }

  /**
   * Generates the HTML for a spinner element.
   * @param {boolean} [includeParagraph=true] - Whether to include a paragraph tag around the spinner.
   * @returns {string} - The HTML string for the spinner element.
   */
  getSpinnerHTML(includeParagraph = true) {
    return (
      (includeParagraph ? '<p align="center">' : '') + '<i class="fa fa-spinner fa-spin"></i>' + (includeParagraph ? '</p>' : '')
    )
  }

  /**
   * Hides the spinner element within the specified element.
   * @param {jQuery} element - The jQuery element containing the spinner.
   * @returns {void}
   */
  hideSpinner(element) {
    element.find('.fa-spinner').remove()
  }

  /**
   * Loads an element using the specified method and parameters.
   * @param {jQuery} element - The jQuery element to load the data into.
   * @param {Function} method - The method to call for loading the element.
   * @param {Array} params - The parameters to pass to the method.
   * @param {boolean} [reinitialize=false] - Whether to reinitialize the application after loading the element.
   * @returns {void}
   */
  loadElement(element, method, params, reinitialize) {
    this.showSpinner(element)
    if (typeof params !== 'undefined') {
      if (params.length === 1) {
        method(params[0], {
          success: data => {
            this.loadElementSuccess(element, data, reinitialize)
          },
          error: () => {
            this.loadElementError(element)
          },
        })
      } else if (params.length === 2) {
        method(params[0], params[1], {
          success: data => {
            this.loadElementSuccess(element, data, reinitialize)
          },
          error: () => {
            this.loadElementError(element)
          },
        })
      } else if (params.length === 3) {
        method(params[0], params[1], params[2], {
          success: data => {
            this.loadElementSuccess(element, data, reinitialize)
          },
          error: () => {
            this.loadElementError(element)
          },
        })
      } else if (params.length === 4) {
        method(params[0], params[1], params[2], params[3], {
          success: data => {
            this.loadElementSuccess(element, data, reinitialize)
          },
          error: () => {
            this.loadElementError(element)
          },
        })
      }
    } else {
      method({
        success: data => {
          this.loadElementSuccess(element, data, reinitialize)
        },
        error: () => {
          this.loadElementError(element)
        },
      })
    }
  }

  /**
   * Handles the error when loading an element.
   * @param {jQuery} element - The jQuery element being loaded.
   * @returns {void}
   */
  loadElementError(element) {
    this.hideSpinner(element)
  }

  /**
   * Handles the success when loading an element.
   * @param {jQuery} element - The jQuery element being loaded.
   * @param {string} data - The data returned from the server.
   * @param {boolean} reinitialize - Whether to reinitialize the application after loading the element.
   * @returns {void}
   */
  loadElementSuccess(element, data, reinitialize) {
    this.showResult(element, data)
    if (reinitialize) {
      this.app.initialize()
    }
  }

  /**
   * Displays the result in the specified element.
   * @param {jQuery} element - The jQuery element to display the result in.
   * @param {string} data - The data returned from the server.
   * @returns {void}
   */
  showResult(element, data) {
    const obj = $.parseJSON(data)
    if (typeof obj !== 'undefined' && typeof obj.resultData !== 'undefined') {
      element.html(obj.resultData)
      this.app.initialize()
    }
  }

  /**
   * Shows the spinner element within the specified element.
   * @param {jQuery} element - The jQuery element to show the spinner in.
   * @param {boolean} [includeParagraph=true] - Whether to include a paragraph tag around the spinner.
   * @returns {boolean} - A boolean indicating whether the spinner was shown successfully.
   */
  showSpinner(element, includeParagraph) {
    this.hideSpinner(element)
    const spinnerHTML = this.getSpinnerHTML(includeParagraph)
    // if (element.html() !== '' && element.html() !== spinnerHTML) return false
    element.append(spinnerHTML)
    return true
  }
}
