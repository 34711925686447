/* global $ */
import BaseView from './BaseView'
import Papa from 'papaparse'

export default class TileEditorQuestionsView extends BaseView {
  initialize() {
    // Upload files
    this.uploadFiles()

    // Set up dynoTable
    const that = this
    $('#t1').dynoTable({
      removeClass: '.remove-row', // Custom remover class name in table
      cloneClass: '.clone-row', // Custom cloner class name in table
      addRowTemplateId: '#add-row-template', // Custom id for row template
      addRowButtonId: '#add-row', // Click this to add a row
      lastRowRemovable: false, // Don't let the table be empty.
      orderable: true, // rows can be rearranged
      dragHandleClass: '.drag-row',
      onRowRemove: () => {
        that.updateCount()
        that.addListeners()
      },
      onRowClone: clonedRow => {
        const count = that.app.getTileEditorQuestionsView().updateCount()
        $('.row-question-editor').each((index, e) => {
          $(e).hide()
        })
        $('.questionActions').each((index, e) => {
          $(e).hide()
        })
        clonedRow.find('.row-fields').each((index, value) => {
          that.updateQuestion($(value), count, true)
        })
        clonedRow.find('.questionActions').show()
        clonedRow.goTo()
        that.updateAnswerTables()
        that.uploadFiles()
        that.addListeners()
      },
      onRowAdd: () => {
        that.updateCount()
        that.uploadFiles()
      },
      onRowReorder: () => {
        that.updateCount()
      },
    })
    $('#t1').sortable({
      //items: 'tr:not(tr:first-child)',
      items: 'tr:is(.questionRow)',
      cursor: 'move',
      handle: '.questionHandle',
      axis: 'y',
      dropOnEmpty: false,
      start: function (e, ui) {
        ui.item.find('.questionButtons').hide()
        $('.row-question-editor').each((index, e) => {
          $(e).hide()
        })
        $('.questionActions').each((index, e) => {
          $(e).hide()
        })
        ui.item.addClass('questionDragging')
      },
      stop: function (e, ui) {
        ui.item.find('.questionButtons').show()
        ui.item.removeClass('questionDragging')
        that.updateQuestions()
      },
    })
    this.updateAnswerTables()
    this.addListeners()
  }

  addListeners() {
    const that = this
    $('.questionRow').on('change', 'input', function () {
      that.updateQuestionPreview($(this).closest('.questionRow'))
    })
    $('.questionRow').on('change', 'select', function () {
      that.updateQuestionPreview($(this).closest('.questionRow'))
    })
    $('.questionRow').each((index, value) => {
      that.updateQuestionPreview($(value))
    })
    $('textarea[name="questionsImport"]').on('input', function () {
      const headerResults = Papa.parse($(this).val(), {
        header: true,
        skipEmptyLines: true,
      })
      const containsQuestionColumn = headerResults.meta.fields.includes('Question')

      const results = Papa.parse($(this).val(), {
        delimiter: ',',
        quoteChar: '"',
        skipEmptyLines: true,
      })
      $('#questionImportCount').html('(' + (containsQuestionColumn ? results.data.length - 1 : results.data.length) + ')')
    })
  }

  moveQuestionDown(question) {
    const row = question.closest('.questionRow')
    row.next().after(row)
    row.goTo()
    this.updateQuestions()
  }

  moveQuestionUp(question) {
    const row = question.closest('.questionRow')
    row.prev().before(row)
    row.goTo()
    this.updateQuestions()
  }

  resetField(object, identifier, newName, value) {
    const element = object.find(identifier)
    if (typeof value !== 'undefined') element.val(value)
    element.attr('name', newName)
    element.attr('id', newName)
    return element
  }

  resetCheckbox(object, identifier, newName, reset) {
    const element = object.find(identifier)
    element.attr('id', newName)
    element.attr('name', newName)
    if (reset) element.prop('checked', false)
    return element
  }

  setQuestionPreviewGenerator(generator) {
    this.generator = generator
  }

  toggleQuestionPanel(element) {
    this.app.getSoundPickerView().hideSoundRecorders()
    element.find('.questionActions').show()
    const fields = element.find('.row-fields')
    if (fields.is(':visible')) {
      $('#t1')
        .find('.row-question-editor')
        .each((index, value) => {
          $(value).hide()
        })
      $('#t1')
        .find('.row-fields')
        .each((index, value) => {
          $(value).hide()
        })
      element.jumpTo()
    } else {
      $('#t1')
        .find('.row-question-editor')
        .each((index, value) => {
          $(value).hide()
        })
      $('#t1')
        .find('.row-fields')
        .each((index, value) => {
          $(value).hide()
        })
      fields.closest('.row-question-editor').show()
      fields.show()
      element.jumpTo()
    }
  }

  updateAnswer(answer, questionCount, count, reset) {
    const index = count - 1
    const rowCorrectId = answer.find('.row-correct-id')
    let questionId = questionCount

    if (rowCorrectId != undefined && rowCorrectId != '') {
      if (questionId == -1) {
        var name = rowCorrectId.attr('name')
        if (name != undefined) {
          var questionIndex = name.indexOf('[')
          var questionIndex2 = name.indexOf(']')
          questionId = name.substring(questionIndex + 1, questionIndex2)
        }
      }
      if (reset) rowCorrectId.val('')
      rowCorrectId.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][id]')

      const rowCorrectText = answer.find('.row-correct-text')
      if (reset) rowCorrectText.val('')
      rowCorrectText.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][text]')

      const rowCorrectTypeId = answer.find('.row-correct-typeId')
      if (reset) rowCorrectTypeId.prop('selectedIndex', 0)
      rowCorrectTypeId.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][typeId]')
    }
    const rowWrongId = answer.find('.row-wrong-id')
    if (rowWrongId != undefined && rowWrongId != '') {
      if (questionId == -1) {
        name = rowWrongId.attr('name')
        if (name != undefined) {
          questionIndex = name.indexOf('[')
          questionIndex2 = name.indexOf(']')
          questionId = name.substring(questionIndex + 1, questionIndex2)
        }
      }
      if (reset) rowWrongId.val('')
      rowWrongId.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][id]')

      var rowWrongText = answer.find('.row-wrong-text')
      if (reset) rowWrongText.val('')
      rowWrongText.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][text]')
    }

    var rowImage = answer.find('.row-image')
    if (reset) rowImage.val('')
    rowImage.attr('name', 'questions[' + questionId + '][image]')

    var rowCorrectImage = answer.find('.row-correct-image')
    if (reset) rowCorrectImage.val('')
    rowCorrectImage.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][image]')

    var rowCorrectImageTmp = answer.find('.row-image-correctAnswer-tmpId')
    if (reset) rowCorrectImageTmp.val('')
    rowCorrectImageTmp.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][imageTmpId]')

    var rowCorrectImagePreview = answer.find('.row-correct-image-preview')
    if (reset) rowCorrectImagePreview.attr('src', 'https://' + location.host + '/img/app/default_60.jpg')

    var rowCorrectSoundFileTmp = answer.find('.row-soundFile-correctAnswer-tmpId')
    if (reset) rowCorrectSoundFileTmp.val('')
    rowCorrectSoundFileTmp.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][soundFileTmpId]')

    var rowCorrectDeleteImage = answer.find('.row-correct-deleteImage')
    rowCorrectDeleteImage.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][deleteImage]')
    if (reset) rowCorrectDeleteImage.prop('checked', false)

    var rowWrongImage = answer.find('.row-wrong-image')
    if (reset) rowWrongImage.val('')
    rowWrongImage.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][image]')

    var rowWrongImageTmp = answer.find('.row-image-wrongAnswer-tmpId')
    if (reset) rowWrongImageTmp.val('')
    rowWrongImageTmp.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][imageTmpId]')

    var rowWrongImagePreview = answer.find('.row-wrong-image-preview')
    if (reset) rowWrongImagePreview.attr('src', 'https://' + location.host + '/img/app/default_60.jpg')

    var rowWrongSoundFileTmp = answer.find('.row-soundFile-wrongAnswer-tmpId')
    if (reset) rowWrongSoundFileTmp.val('')
    rowWrongSoundFileTmp.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][soundFileTmpId]')

    var rowWrongDeleteImage = answer.find('.row-wrong-deleteImage')
    rowWrongDeleteImage.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][deleteImage]')
    if (reset) rowWrongDeleteImage.prop('checked', false)

    // Reset correct answers
    rowCorrectImage = answer.find('.row-image-correctAnswer')
    if (reset) rowCorrectImage.val('')
    rowCorrectImage.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][image]')
    rowCorrectImagePreview = answer.find('.row-image-preview-correctAnswer' + index)
    if (reset) rowCorrectImagePreview.attr('src', 'https://' + location.host + '/img/app/default_60.jpg')

    var rowImageEnabled = answer.find('.correctAnswerCheckboxImage')
    rowImageEnabled.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][imageEnabled]')
    if (reset) rowImageEnabled.prop('checked', false)

    var rowCorrectVideo = answer.find('.row-video-correctAnswer')
    rowCorrectVideo.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][video]')
    if (reset) rowCorrectVideo.val('')

    var rowVideoEnabled = answer.find('.correctAnswerCheckboxVideo')
    rowVideoEnabled.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][videoEnabled]')
    if (reset) rowVideoEnabled.prop('checked', false)

    rowCorrectDeleteImage = answer.find('.row-correct-deleteImage')
    rowCorrectDeleteImage.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][deleteImage]')
    if (reset) rowCorrectDeleteImage.prop('checked', false)

    var rowSoundEnabled = answer.find('.correctAnswerCheckboxSound')
    rowSoundEnabled.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][soundEnabled]')
    if (reset) rowSoundEnabled.prop('checked', false)

    // Reset sound fields
    var rowSound = answer.find('.row-sound-correctAnswer')
    if (reset) rowSound.val('')
    rowSound.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][sound]')

    var rowSoundType = answer.find('.row-soundType-correctAnswer')
    if (reset) rowSoundType.val('')
    rowSoundType.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][soundType]')

    var rowSoundFile = answer.find('.row-soundFile-correctAnswer')
    if (reset) rowSoundFile.val('')
    rowSoundFile.attr('name', 'questions[' + questionId + '][correctAnswers][' + index + '][soundFile]')

    // Reset wrong answers
    rowWrongImage = answer.find('.row-image-wrongAnswer')
    if (reset) rowWrongImage.val('')
    rowWrongImage.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][image]')
    rowWrongImagePreview = answer.find('.row-image-preview-wrongAnswer' + index)
    if (reset) rowWrongImagePreview.attr('src', 'https://' + location.host + '/img/app/default_60.jpg')

    rowImageEnabled = answer.find('.wrongAnswerCheckboxImage')
    rowImageEnabled.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][imageEnabled]')
    if (reset) rowImageEnabled.prop('checked', false)

    var rowWrongVideo = answer.find('.row-video-wrongAnswer')
    rowWrongVideo.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][video]')
    if (reset) rowWrongVideo.val('')

    rowVideoEnabled = answer.find('.wrongAnswerCheckboxVideo')
    rowVideoEnabled.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][videoEnabled]')
    if (reset) rowVideoEnabled.prop('checked', false)

    rowWrongDeleteImage = answer.find('.row-wrong-deleteImage')
    rowWrongDeleteImage.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][deleteImage]')
    if (reset) rowWrongDeleteImage.prop('checked', false)

    rowSoundEnabled = answer.find('.wrongAnswerCheckboxSound')
    rowSoundEnabled.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][soundEnabled]')
    if (reset) rowSoundEnabled.prop('checked', false)

    // Reset sound fields
    rowSound = answer.find('.row-sound-wrongAnswer')
    if (reset) rowSound.val('')
    rowSound.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][sound]')

    rowSoundType = answer.find('.row-soundType-wrongAnswer')
    if (reset) rowSoundType.val('')
    rowSoundType.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][soundType]')

    rowSoundFile = answer.find('.row-soundFile-wrongAnswer')
    if (reset) rowSoundFile.val('')
    rowSoundFile.attr('name', 'questions[' + questionId + '][wrongAnswers][' + index + '][soundFile]')

    var answerBox = answer.find('.answerBlockImage')
    if (reset) answerBox.hide()
    answerBox = answer.find('.answerBlockSound')
    if (reset) answerBox.hide()
    answerBox = answer.find('.answerBlockVideo')
    if (reset) answerBox.hide()

    // Hide play button
    var playButton = answer.find('.play-button')
    playButton.hide()

    // Uncheck media boxes
    if (reset) {
      var answerCheckbox = answer.find('.answerCheckboxImage')
      answerCheckbox.prop('checked', false)
      answerCheckbox = answer.find('.answerCheckboxSound')
      answerCheckbox.prop('checked', false)
      answerCheckbox = answer.find('.answerCheckboxVideo')
      answerCheckbox.prop('checked', false)
    }
  }

  updateQuestion(question, count, reset) {
    const prefix = 'questions[' + (count - 1) + ']'
    const planGamesPrefix = 'planGames[' + (count - 1) + ']'

    // Reset basic question fields
    this.resetField(question, '.row-id', prefix + '[id]', reset ? '' : undefined)
    let element = this.resetField(question, '.row-game-name', planGamesPrefix + '[name]')
    if (element.attr('type') !== 'hidden') {
      element.val('')
      element.focus()
    }
    this.resetField(question, '.row-position', prefix + '[position]', count - 1)
    element = this.resetField(question, '.row-text', prefix + '[text]')
    if (reset && element.attr('type') !== 'hidden') {
      element.val('')
      element.focus()
    }
    element = this.resetField(question, '.row-description', prefix + '[description]')
    if (reset && element.attr('type') !== 'hidden') {
      element.val('')
    }
    this.resetField(question, '.row-x', prefix + '[x]', '')
    this.resetField(question, '.row-y', prefix + '[y]', '')

    // Reset plan game
    this.resetField(question, '.row-plangame-id', planGamesPrefix + '[id]', reset ? '' : undefined)
    this.resetField(question, '.row-game-name', planGamesPrefix + '[title]', reset ? '' : undefined)
    this.resetField(question, '.row-plangame-name', planGamesPrefix + '[title]', reset ? '' : undefined)
    this.resetField(question, '.row-plangame-appName', planGamesPrefix + '[applicationName]', reset ? '' : undefined)
    this.resetField(question, '.row-plangame-appid', planGamesPrefix + '[applicationId]', reset ? '' : undefined)
    var rowGamePreview = question.find('.row-plangame-preview')
    if (reset) rowGamePreview.html('')

    // Reset image fields
    const rowImagePreview = question.find('.row-image-preview-question')
    if (reset) rowImagePreview.attr('src', 'https://' + location.host + '/img/app/default_60.jpg')
    this.resetField(question, '.row-image-question', prefix + '[image]', reset ? '' : undefined)
    this.resetField(question, '.row-image-question-tmpId', prefix + '[imageTmpId]', reset ? '' : undefined)
    this.resetCheckbox(question, '.row-deleteImage', prefix + '[deleteImage]', reset)
    this.resetCheckbox(question, '.questionCheckboxImage', prefix + '[imageEnabled]', reset)

    // Reset sound fields
    this.resetField(question, '.row-sound', prefix + '[sound]', reset ? '' : undefined)
    this.resetField(question, '.row-soundType', prefix + '[soundType]', reset ? '' : undefined)
    this.resetField(question, '.row-soundFile', prefix + '[soundFile]', reset ? '' : undefined)
    this.resetField(question, '.row-soundFile-question-tmpId', prefix + '[soundFileTmpId]', reset ? '' : undefined)
    this.resetCheckbox(question, '.questionCheckboxSound', prefix + '[soundEnabled]', reset)

    // Reset video fields
    this.resetField(question, '.row-video-question', prefix + '[video]', reset ? '' : undefined)
    this.resetCheckbox(question, '.questionCheckboxVideo', prefix + '[videoEnabled]', reset)

    if (reset) {
      question.find('.questionBlockImage').hide()
      question.find('.questionBlockSound').hide()
      question.find('.questionBlockVideo').hide()

      // Hide play button
      const playButton = question.find('.play-button')
      playButton.hide()
    }

    // Update property fields
    question.find('[class*="row-property"]').each((index, element) => {
      const propertyName = $(element).attr('name')
      if (propertyName !== undefined) {
        const firstPart = propertyName.substr(0, propertyName.indexOf('[') + 1)
        const lastPart = propertyName.substr(propertyName.indexOf(']'), propertyName.length)
        const result = firstPart + (count - 1) + lastPart
        $(element).attr('name', result)
      }
      if (reset) {
        if ($(element).is(':checkbox') || $(element).is(':radio')) {
          $(element).prop('checked', false)
        } else if ($(element).is('select')) {
          const options = $(element).find('option')
          if (options !== undefined && options.length > 0) {
            options[0].selected = true
          }
        } else if ($(element).is(':text')) {
          $(element).val('')
        }
      }
    })

    // Update template
    question.find('.add-answer-row-template').each((index, element) => {
      this.updateAnswer($(element), count - 1, 1, reset)
    })

    // Reset answers
    let answerCount = 0
    question.find('.row-correct-answer').each((index, element) => {
      answerCount++
      if (reset && answerCount > window.defaultCorrectNumberOfFields) $(element).remove()
      else this.updateAnswer($(element), count - 1, answerCount, reset)
    })
    answerCount = 0
    question.find('.row-wrong-answer').each((index, element) => {
      answerCount++
      if (reset && answerCount > window.defaultWrongNumberOfFields) $(element).remove()
      else this.updateAnswer($(element), count - 1, answerCount, reset)
    })

    // Hide question body
    if (reset) {
      $('#t1')
        .find('.row-fields')
        .each((index, value) => {
          $(value).hide()
        })
      question
        .closest('.questionRow')
        .find('.questionImage')
        .attr('src', 'https://' + location.host + '/img/app/default_question.png')
      question.closest('.questionRow').find('.questionPreviewText').html('')
      question.closest('.questionRow').find('.row-question-editor').show()
      question.closest('.questionRow').find('.questionActions').show()
      question.closest('.questionRow').find('.row-fields').show()
    } else {
      question.closest('.questionRow').find('.row-fields').hide()
    }
  }

  updateQuestionPreview(question) {
    const questionText = $(question).find('[name*=text]')
    if (questionText !== undefined && questionText.length > 0) {
      $(question)
        .find('.questionPreviewText')
        .html(questionText.val() + (this.generator !== undefined ? this.generator(question) : ''))
      const image = $(question).find('.row-image-preview-question').attr('src')
      if (image !== undefined && image !== '' && image.indexOf('data:image') === -1) {
        $(question).find('.questionImage').attr('src', image)
      }
    }
    const stackText = $(question).find('.planGameName').first()
    if (stackText !== undefined && stackText.length > 0) {
      $(question).find('.questionPreviewText').html(stackText.text())
      const image = $(question).find('.planGameImage').attr('src')
      if (image !== undefined && image !== '' && image.indexOf('data:image') === -1) {
        $(question).find('.questionImage').attr('src', image)
      }
    }
  }

  updateQuestions() {
    let count = 1
    $('.row-fields').each((index, value) => {
      $(value).closest('.questionRow').find('.row-count').html(count)
      this.updateQuestion($(value), count, false)
      count++
    })
  }

  /**
   * Updates the current rows
   */
  updateCount() {
    let count = 0
    $('.row-count').each((index, value) => {
      count++
      $(value).html(count)
    })
    return count
  }

  /**
   * Updates the current rows
   */
  updateAnswerCount(tr) {
    let answerCount = 0
    tr.closest('.t2')
      .find('.row-answer-count')
      .each(() => {
        answerCount++
      })
    return answerCount
  }

  updateAnswerTables() {
    const that = this
    $('.t2').dynoTable({
      removeClass: '.remove-answer-row', // Custom remover class name in table
      cloneClass: '.clone-answer-row', // Custom cloner class name in table
      addRowTemplateId: '.add-answer-row-template', // Custom id for row template
      addRowButtonId: '.add-answer-row', // Click this to add a row
      lastRowRemovable: true, // Don't let the table be empty.
      orderable: true, // rows can be rearranged
      dragHandleClass: '.drag-row',
      onRowRemove: table => {
        let count = 0
        table.find('.row-answer-fields').each((index, value) => {
          that.updateAnswer($(value), -1, count, false)
          count++
        })
        const table2 = table.closest('.dynotable')
        if (window.maximumNumberOfAnswers > 0 && count >= window.maximumNumberOfAnswers) {
          table2.find('.add-answer-row').first().hide()
        } else {
          table2.find('.add-answer-row').first().show()
        }
      },
      onRowClone: clonedRow => {
        const count = that.updateAnswerCount(clonedRow)
        clonedRow.find('.row-answer-fields').each((index, value) => {
          that.updateAnswer($(value), -1, count, true)
        })
        clonedRow.goTo()
        clonedRow.find('.row-correct-text').focus()
        clonedRow.find('.row-wrong-text').focus()
        const table = clonedRow.closest('.t2').closest('.dynotable')
        if (window.maximumNumberOfAnswers > 0 && count >= window.maximumNumberOfAnswers) {
          table.find('.add-answer-row').first().hide()
        } else {
          table.find('.add-answer-row').first().show()
        }
      },
      onRowAdd: addedRow => {
        if (addedRow.hasClass('correct')) {
          addedRow.removeClass('add-answer-row-template correct').addClass('row-answer row-correct-answer')
        } else {
          addedRow.removeClass('add-answer-row-template wrong').addClass('row-answer row-wrong-answer')
        }
        const count = that.updateAnswerCount(addedRow)
        addedRow.find('.row-answer-fields').each((index, value) => {
          that.updateAnswer($(value), -1, count - 1, true)
        })
        addedRow.find('.row-correct-text').focus()
        addedRow.find('.row-wrong-text').focus()
        const table = addedRow.closest('.t2').closest('.dynotable')
        if (window.maximumNumberOfAnswers > 0 && count >= window.maximumNumberOfAnswers) {
          table.find('.add-answer-row').first().hide()
        } else {
          table.find('.add-answer-row').first().show()
        }
        that.uploadFiles()
      },
      onRowReorder: () => {},
    })
  }

  uploadFiles() {
    $('.imageUpload').on('change', event => {
      this.app.getTileEditorView().uploadImage($(event.currentTarget))
    })
    $('.soundUpload').on('change', event => {
      this.app.getTileEditorView().uploadSound($(event.currentTarget))
    })
  }
}
