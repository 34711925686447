/* global $ */
import {webService} from '../util/WebServiceManager'
import BaseView from './BaseView'

export default class TileDescriptionView extends BaseView {
  /**
   * Confirm an action on the tile.
   * @param text Text to display in dialogue.
   * @returns {boolean} Returns true if confirmed, false otherwise.
   */
  confirmAction (text) {
    if (confirm(text)) {
      this.app.enablePress()
      return true
    }
    return this.app.disablePress()
  }

  /**
   * Go to text field to write comment.
   */
  postComment () {
    $('.tab2').tab('show')
    $('#writeComment').focus()
  }

  /**
   * Show results for the given tile identifier.
   * @param element Element to show results in.
   * @param tileId Identifier of tile to show.
   */
  showResults (element, tileId) {
    if (element.html() !== '') return
    this.loadElement(element, webService.getResults, [tileId], true)
  }

  /**
   * Toggle like on a given tile.
   * @param element DOM element.
   * @param tileId Identifier of tile.
   */
  toggleLike (element, tileId) {
    webService.toggleLike(tileId, {
      success: (data) => {
        const obj = $.parseJSON(data)
        if (typeof obj !== 'undefined' && typeof obj.resultData !== 'undefined') {
          element.html(obj.resultData.buttonContent)
          $('#tab2').html(obj.resultData.feedbackTab)
          $('.likeCount').each(function () {
            $(this).html(obj.resultData.likeCount)
          })
        }
      }
    })
  }
}
