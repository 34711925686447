import React from 'react'
import { Chart } from './chart/Chart.jsx'
import { MostPopularTiles } from './mostpopulartiles/MostPopularTiles.jsx'

export const Activity = ({ regionId = '', groupId = '', year = '', webService = undefined, onLoad = () => {} }) => {
  return (
    <React.Fragment>
      <Chart regionId={regionId} groupId={groupId} year={year} webService={webService} />
      <MostPopularTiles regionId={regionId} groupId={groupId} year={year} onLoad={onLoad} webService={webService} />,
    </React.Fragment>
  )
}
