/* global $ */
import {state} from './State'

export default class LanguageLoader {
  /**
   * Load language strings.
   * @param name Name of language file.
   * @param path Path to language file.
   * @param language Language identifier.
   * @param handler Handler function when language has been loaded.
   */
  loadLanguage (handler) {
    const language = document.documentElement.lang
    console.log('Load language: ' + language)
    $.i18n.properties({
      name: 'app',
      path: location.protocol + '//' + location.host + (location.pathname.indexOf('beta') > 0 ? '/beta' : '') + '/scripts/app/languages/',
      mode: 'both',
      language: language,
      async: true,
      cache: true,
      callback: function () {
        state.languageLoaded = true
        if (typeof handler !== 'undefined') {
          handler()
        }
      }
    })
  }

  /**
   * Translate given string.
   * @param name Key to translate.
   * @param value1 Value to insert dynamically.
   * @param value2 Value to insert dynamically.
   * @returns {*} Translated string.
   */
  translate (name, value1, value2) {
    if (typeof window['l' + name] !== 'undefined') {
      if (typeof value1 !== 'undefined' && value1 !== '') {
        return window['l' + name](value1)
      }
      if (typeof value2 !== 'undefined' && value2 !== '') {
        return window['l' + name](value2)
      }
      return window['l' + name]
    }
    return name
  }
}

// Export global language manager
if (typeof window.languageManager === 'undefined') {
  window.languageManager = new LanguageLoader()
}
export const languageManager = window.languageManager

/**
 * Convenience function to translate string.
 * @param name Key to translate.
 * @param value1 Value to insert dynamically.
 * @param value2 Value to insert dynamically.
 * @returns {*} Translated string.
 */
export function l (name, value1, value2) {
  return languageManager.translate(name, value1, value2)
}
